<template>
  <div id="sidebar" :style="{ 'background-image': 'url(' + bg_bl + ')' }">
    <div class="nav">
      <ul class="navTab">
        <li v-for="item in navTab" :key="item.key" @click="handleClick(item)" :class="{ alive: navKey === item.key }">
          <div class="ico">
            <img :src="item.icon" />
          </div>
        </li>
      </ul>
      <div class="daily-remind" @click="$store.commit('set', { type: 'remind', data: true })">
        <div class="remind-icon">
          <img src="@/assets/image/icon-progress/icon_tx.png" width="30px" />
        </div>
      </div>
      <div class="login" @click.stop="showSetMenu = !showSetMenu">{{ this.account }}</div>
      <ul v-if="showSetMenu" class="set-menu">
        <li v-for="item in setMenu" @click.stop="openSetting(item.key)" :key="item.key">
          <img :src="item.img" />
          <span>{{ item.text }}</span>
        </li>
      </ul>
    </div>
    <div :class="['second-nav', !isExpand ? 'shink-nav' : 'grow-nav']">
      <div class="today">
        <span>{{ realTime }}</span>
      </div>
      <ul class="tab">
        <li
          v-for="item in lastList"
          :key="item.key"
          @mouseenter="mouseenter($event, item)"
          @mouseleave="mouseleave($event, item)"
        >
          <router-link :to="item.link">
            <img v-if="item.link === $route.path" :src="item.activeIco" alt="yes" width="24" />
            <img v-else :src="item.ico" alt="no" width="24" />
            <span v-if="isExpand">{{ item.str }}</span>
          </router-link>
        </li>
      </ul>
      <div class="from">医键通软件开发有限公司</div>
      <div @click="isExpand = !isExpand" :class="[isExpand ? 'icon-left' : 'icon-right', 'expand']" />
    </div>
    <login v-if="!isLogin" @login="login" @click="$event.stopPropagation(), $event.preventDefault()" />
    <setting
      @setup="setupComplete"
      @skinChange="skinChange"
      :type="setting.type"
      :account="account"
      :bgBli="bgBli"
      v-if="isSetting"
      @click="$event.stopPropagation(), $event.preventDefault()"
    />
  </div>
</template>

<script>
import login from '@/components/sidebar/login';
import setting from '@/components/sidebar/setting/setting';
import { getLogOutUrl } from '@/service/help';
import { routerLink } from '@/common/js';
import cookie from '@/utils/cookie';
import { dealWithTime } from '@/utils/utils';
export default {
  name: 'sidebar',
  data() {
    return {
      isExpand: true,
      navTab: routerLink,
      navKey: '',
      lastList: [], //
      realTime: '',
      showSetMenu: false, //打开设置菜单
      isSetting: false, //是否打开设置页
      setting: {
        type: '',
      },
      //设置列表
      bg_bl: require('../../assets/image/sidebar/bg_bl.png'),
      bgBli: 0,
    };
  },
  components: {
    login,
    setting,
  },
  created() {
    //获取皮肤
    this.skinChange(cookie(document).get('bg_bl_num'));
    //保存登录状态和路由根据cookie初始化数据
    if (cookie(document).get('name') === undefined) {
      this.login(false);
    } else {
      this.logging();
    }
    if (localStorage.getItem('navKey') === null) {
      //刚开始要是空的添加任务管理
      this.handleClick(this.navTab[0]);
      this.$store.commit('set', { type: 'path', data: '/chat/communicate' });
    } else if (localStorage.getItem('navKey') === 'chat') {
      // this.navKey = localStorage.getItem('navKey');
      // this.lastList = this.navTab[0].list;
      // this.$store.commit('set', { type: 'path', data:  localStorage.getItem('fromPath')});
    } else if (localStorage.getItem('navKey') === 'task') {
      this.navKey = localStorage.getItem('navKey');
      // console.log(this.navTab, 'this.navTab');
      this.lastList = this.navTab[0].list;
      // if(localStorage.getItem('fromPath').indexOf('/maintain') != -1){
      //   this.navKey = 'maintain'
      //   this.lastList = this.navTab[1].list;
      //   return
      // } 
      this.$store.commit('set', { type: 'path', data:  localStorage.getItem('fromPath') });
    }else if (localStorage.getItem('navKey') === 'maintain') {
      this.navKey = localStorage.getItem('navKey');
      // console.log(this.navTab, 'this.navTab');
      this.lastList = this.navTab[1].list;
      this.$store.commit('set', { type: 'path', data:  localStorage.getItem('fromPath') });
    }
    // console.log(this.lastList, 'this.lastList');
  },
  mounted() {
    // 页面加载完后显示当前时间
    this.realTime = dealWithTime(new Date());
    // this.openSetting('role');
  },
  methods: {
    handleClick(item) {
      let key = item.key;
      if (this.navKey !== key) {
        //防止重复点击当前菜单导致错误路由保存
        this.navKey = key;
        this.lastList = item.list;
        let path = this.$route.path;
        this.$router.push({ path: this.$store.state.path });
        localStorage.setItem('navKey', key);
        localStorage.setItem('fromPath', path);
        this.$store.commit('set', { type: 'path', data: path });
        this.navKey = key;
      }
    },
    mouseenter(event, ico) {
      let target = event.target.firstChild.firstChild;
      target.setAttribute('src', ico.activeIco);
    },
    mouseleave(event, ico) {
      let target = event.target.firstChild.firstChild;
      if (target.getAttribute('alt') === 'yes') target.setAttribute('src', ico.activeIco);
      else target.setAttribute('src', ico.ico);
    },
    login(data) {
      this.$store.commit('set', { type: 'isLogin', data });
    },
    logOut() {
      this.$confirm('是否退出登录？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      })
        .then(() => {
          getLogOutUrl().then(res => {
            cookie(document).remove('name');
            cookie(document).remove('account');
            cookie(document).remove('password');
            this.$store.dispatch('onLogout');
          });
          this.$store.commit('setAccount', '');
          this.login(false);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '以取消退出登录',
          });
        });
    },
    logging() {
      /*      console.log(cookie(document).get('password'));*/
      //刷新自己登录
      this.$store
        .dispatch('login', {
          account: cookie(document).get('account'),
          password: cookie(document).get('password'),
          type: 'ap',
        })
        .then(res => {
          if (res.success) {
            this.login(true);
          } else {
            this.$message({
              type: 'error',
              message: '账户或密码错误',
            });
            this.login(false);
          }
        })
        .catch(e => {
          //连接不上发生错误
          alert(e);
          this.login(false);
        });
    },
    openSetting(type) {
      //打开设置页
      this.showSetMenu = false;
      if (type == 'logout') {
        this.logOut();
      } else {
        this.$set(this.setting, 'type', type);
        this.isSetting = true;
      }
    },
    setupComplete(data) {
      //完成设置方法
      console.log(data);
      this.isSetting = false;
    },
    skinChange(data) {
      //改变皮肤
      if (data + '' == 'undefined') {
        data = 0;
      }
      this.bgBli = Number(data) || 0;
      cookie(document).set('bg_bl_num', data, { path: '/' });
      data = data == 0 ? '' : data.toString();
      this.bg_bl = require('../../assets/image/sidebar/bg_bl' + data + '.png');
    },
  },
  computed: {
    account() {
      let name = this.$store.state.account || '未知';
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
    routePath() {
      return this.$route.path;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    setMenu() {
      let menu = [
        {
          key: 'role',
          img: require('assets/image/sidebar/role.png'),
          text: '角色管理',
        },
        {
          key: 'menber',
          img: require('assets/image/sidebar/menber.png'),
          text: '成员管理',
        },
        {
          key: 'account',
          img: require('assets/image/sidebar/account.png'),
          text: '账户设置',
        },

        {
          key: 'skin',
          img: require('assets/image/sidebar/skin.png'),
          text: '皮肤设置',
        },
        {
          key: 'logout',
          img: require('assets/image/sidebar/logOut.png'),
          text: '退出登录',
        },
      ];
      if (this.userInfo.level == '1') {
        menu.splice(0, 1);
      } else if (this.userInfo.level == '2') {
        menu.splice(0, 2);
      }

      return menu;
    },
  },
};
</script>
<style scoped lang="less">
@import url('~@/assets/style/sidebar/sidebar.less');
</style>
