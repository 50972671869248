<template>
  <div class="set-main menber" @click="clearPopover">
    <aside>
      <div class="search">
        <input class="search-input" placeholder="搜索" v-model="partSearch" @keyup.enter="getData" />
      </div>
      <div class="tree-box">
        <el-tree
          ref="tree"
          :data="partTree"
          accordion
          :default-expand-all="true"
          node-key="id"
          @node-click="handleNodeClick"
          :filter-node-method="filterNode"
          :props="defaultProps"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <div class="icon-root" v-if="data.deep == 0"></div>
            <span class="text-label">{{ node.label }} {{ data.deep !== 0 ? '(' + data.udata.length + ')' : '' }}</span>
            <div @click.stop>
                <el-popover v-if="type == 'menber'" placement="bottom" trigger="click" class="span-pop" :ref="'popover-'+data.id+data.name">
                  <template v-if="data.deep == 0">
                    <div class="popover-item" @click="openDialog('add')">添加部门</div>
                  </template>
                  <template v-else>
                    <div class="popover-item" @click="handleInitEdt(data)">编辑部门</div>
                    <!-- <div class="popover-item" @click="openDialog('addsub')">添加子部门</div> -->
                    <div class="popover-item" @click="handleDelSection(data.id)">删除部门</div>
                  </template>
                  <div slot="reference">
                    <div class="tree-menu" @click="getTreeId($event,data.id+data.name)"></div>
                    <!-- <i class="el-icon-collection"></i> -->
                  </div>
                </el-popover>
            </div>
          </span>
        </el-tree>
      </div>
    </aside>
    <main>
      <template>
        <div class="menber-top">
          <span class="title">{{ tableTitle }}</span>
          <template v-if="type == 'menber'">
            <div v-if="!isRootNode" style="display: inline-block; position: absolute; margin-top: 60px; right: 290px">
              <member
                v-if="showMemberAdd"
                :multiple="true"
                :currArr="currMember"
                @pickit="handleAddMemberPickit"
              ></member>
              <div
                class="cover-mark"
                v-show="showMemberAdd"
                @click="$event.stopPropagation(), (showMemberAdd = false)"
              ></div>
            </div>
            <el-button
              v-if="!isRootNode"
              type="primary"
              icon="el-icon-plus"
              @click="
                () => {
                  showMemberAdd = !showMemberAdd;
                }
              "
              >添加成员</el-button
            >
            <!-- <el-button
              v-for="item in filbtn"
              :key="item.val"
              class="text-btn"
              @click="handleCurbtn(item.val)"
              :type="curbtn == item.val ? 'primary' : 'default'"
              >{{ item.str }}</el-button
            > -->
          </template>
          <template v-else-if="type == 'role'">
            <div v-if="showMemberAdd" style="display: inline-block; position: absolute; margin-top: 60px; right: 290px">
              <member :multiple="true" :currArr="currMember" @pickit="handleAddRolePickit"></member>
              <div
                class="cover-mark"
                v-show="showMemberAdd"
                @click="$event.stopPropagation(), (showMemberAdd = false)"
              ></div>
            </div>
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="
                () => {
                  showMemberAdd = true;
                }
              "
              >添加管理员</el-button
            >
          </template>
        </div>
      </template>
      <el-table style="width: 100%" :data="filtersData" stripe v-if="filtersData.length > 0">
        <el-table-column
          width="150"
          header-row-class-name="center-cell"
          class-name="center-cell"
          type="index"
          label="序号"
        ></el-table-column>
        <el-table-column
          v-for="item in tableCol"
          :key="item.prop"
          header-align="center"
          align="center"
          :prop="item.prop"
          :width="item.width"
          :label="item.label"
          :class-name="item.prop == 'fun' ? 'center-cell' : ''"
        >
          <template slot-scope="scope">
            <div v-if="item.prop == 'fun'">
              <el-button class="fun-btn" @click="handleInitEdtMember(scope.row)">编辑</el-button>
              
              <template v-if="type != 'menber'">
                <el-button class="fun-btn" type="warning" @click="handleDelRole(scope.row)">删除</el-button>
              </template>
            </div>
            <div v-else-if="item.prop == 'name'">
              <div class="head-draw" style="margin-right: 16px">{{ lastName(scope.row[item.prop]) }}</div>
              <span>{{ scope.row[item.prop] }}</span>
            </div>
            <div v-else-if="item.prop == 'level'">
              <span>{{ roleText[scope.row[item.prop]]}}</span>
            </div>
            <div v-else-if="item.prop == 'sname'">
               <span>{{isRootNode ? scope.row.secnas.join(',') : scope.row[item.prop]}}</span>
            </div>
            <div v-else>
              <span>{{ scope.row[item.prop] }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </main>
    <el-dialog
      class="section-form"
      :title="sectionFormTitle"
      :visible.sync="dialogVisible"
      width="660px"
      @close="closeDialog"
      append-to-body
    >
      <div>
        <el-form ref="form" :model="sectionForm" label-width="100px">
          <template v-if="formType == 'edt' || formType == 'add' || formType == 'addsub'">
            <el-form-item label="部门名称">
              <el-input v-model="sectionForm.title"></el-input>
            </el-form-item>
            <el-form-item label="部门管理员">
              <!-- <div :style="membcImg" class="admin" v-if="sectionForm.admin" @click="sectionForm.admin = null">
                <span class="del-member el-icon-close" />
              </div>-->
              <div
                v-for="(item, ind) in sectionForm.admin"
                :style="getMemImg(item.avatar)"
                class="admin"
                @click="delAdmin(ind)"
                :key="'mem'+ind"
                :title="item.name"
              >
                <span class="del-member el-icon-close" />
              </div>
              <div class="mem" @click="$event.stopPropagation(), (showMember = true)">
                <member
                  v-if="showMember"
                  style="margin-top: 35px"
                  @click="$event.stopPropagation()"
                  :multiple="true"
                  :currArr="sectionForm.admin.map(v => v.id)"
                  @pickit="pickitMem"
                />
                <div
                  class="cover-mark"
                  v-show="showMember"
                  @click="$event.stopPropagation(), (showMember = false)"
                ></div>
              </div>
            </el-form-item>
            <!-- <el-form-item v-if="formType == 'edt' || formType == 'addsub'" label="所属部门">
              <el-select v-model="sectionForm.belong" placeholder="请选择所属部门">
                <el-option v-for="opt in sectorLis" :key="opt.id" :label="opt.label" :value="opt.id"></el-option>
              </el-select>
            </el-form-item>-->
          </template>
          <template v-else-if="formType == 'edtmem'">
            <el-form-item label="姓名">
              <el-input v-model="sectionForm.mem_name"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="sectionForm.mem_phone"></el-input>
            </el-form-item>
            <el-form-item label="所属部门">
              <el-select v-model="sectionForm.mem_belong" :multiple="isRootNode"  placeholder="请选择所属部门" @change="handleChange" @remove-tag="hanleRemove">
                <el-option v-for="opt in sectorLis" :key="opt.id" :label="opt.label" :value="opt.id" @click.native="handleOpt(opt)"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { lastName } from '@/utils/utils';
import { memberMixin } from '@/common/js';

import {
  sectorLis,
  sectorNew,
  sectorMod,
  sectorDel,
  sectorUserNew,
  sectorUserDel,
  sectorUserMod,
  sectorUserStatus,
  sectorRoleLis,
  sectorRoleMod,
  sectorRoleDel,
  sectorRoleAdd,
  sectorRoleDepAdd,
  sectorRoleDepDel
} from '@/service/help';
import Member from '@/components/task/progress/member';
// import partTree from './mock.json';
export default {
  props: {
    type: String,
  },
  mixins: [memberMixin],
  data() {
    return {
      partSearch: '', //关键词
      partTree: [], //树状数据
      sectorLis: [], //整理的部门
      defaultProps: {
        //定义的字段
        children: 'subsector',
        label: 'name',
      },
      roleText:{
        '0':'超级管理员',
        '1':'管理员',
        '2':'普通成员'
      },
      tableCol: [
        //右边表头
        {
          label: '姓名',
          prop: 'name',
          width: '250px',
        },
        {
          label: '手机号',
          prop: 'phone',
          width: '',
        },
        {
          label: '所属部门',
          prop: 'sname',
          width: '',
        },
        {
          label: '权限',
          prop: 'level',
          width: '',
        },
        {
          label: '操作',
          prop: 'fun',
          width: '200px',
        },
      ],
      curNode: null,
      tableData: [],
      tableTitle: '',
      filbtn: [
        {
          str: '已禁用',
          val: 'disable',
        },
        {
          str: '正常',
          val: 'normal',
        },
      ],
      curbtn: '', //'normal' 'disable' ''
      isRootNode: true, //是否为根节点
      dialogVisible: false,
      sectionFormTitle: '', //标题
      formType: '', //表单类型
      sectionForm: {
        id: '',
        name: '', //姓名
        title: '', //部门名称
        phone: '', //手机号
        maid:'',
        admin: [],
        oldAdmin:[],
        belong: '', //所属部门
        mem_id: '', //成员信息
        mem_name: '',
        mem_phone: '',
        mem_belong: [],
      },
      showMember: false,
      showMemberAdd: false,
      // popover
      popover_text:[],
      is_edit_dep:false // 是否用户编辑了部门
    };
  },
  computed: {
    membcImg() {
      return this.sectionForm.admin == null ? '' : 'background-image:url(' + this.sectionForm.admin.avatar + ')';
    },
    currMember() {
      return this.tableData.map(v => {
        if (this.type == 'menber') {
          return v.id;
        } else {
          return v.user_id;
        }
      });
    },
    filtersData() {
      //目前仅支持禁用和非禁用的
      let filMap = {
        disable: ['1'],
        normal: ['0'],
        '': ['1', '0'],
      };
      let fil = filMap[this.curbtn];
      return this.tableData.filter(v => {
        return this.type == 'menber' ? fil.includes(v.disable) : true;
      });
    },
  },
  created() {
    console.log(this.type,'type');
    this.getData();
   
  },
  methods: {
    handleOpt(opt){
      console.log(opt,'999')
      if(!this.isRootNode) return
      let data = {
          id:opt.id,
          user_id:this.sectionForm.mem_id
      }
      let url = this.sectionForm.mem_belong.includes(opt.id) ? sectorRoleDepAdd : sectorRoleDepDel
      url(data).then(res=>{
          if(!res.data.success){
            this.$message({
              type: 'warning',
              message: res.data.msg,
            });
            return
          }
      })
    },
    hanleRemove(tag){
      console.log(tag)
      let data = {
          id:tag,
          user_id:this.sectionForm.mem_id
      }
      sectorRoleDepDel(data).then(res=>{
          if(!res.data.success){
            this.$message({
              type: 'warning',
              message: res.data.msg,
            });
            return
          }
      })
    },
    handleChange(val){
      console.log(val,this.sectionForm.mem_belong)
      if(!this.isRootNode){
         this.is_edit_dep = true
      }
      this.sectionForm.mem_belong = val
    },
    clearPopover(){
      this.popover_text.map(v=>{
        this.$refs[`popover-${v}`].doClose()
      })
      
      this.popover_text = []
    },
    getTreeId(e,text){
      if(!this.popover_text.includes(text)){
        this.popover_text.push(text)
      }
    },
    delAdmin(ind) {
      this.sectionForm.admin.splice(ind, 1);
    },
    getMemImg(url) {
      return url ? 'background-image:url(' + url + ')' : '';
    },
    //成员管理相关
    handleAddMemberPickit(item) {
      let join_id = item.id;
      console.log(this.currMember, join_id, this.currMember.includes(join_id));
      if (this.currMember.includes(join_id)) {
        sectorUserDel({ join_id, id: this.curNode.id }).then(res => {
          if (res.data.success) {
            this.$message({
              type: 'success',
              message: '删除成功',
            });
            //刷新逻辑
            this.getData(this.curNode);
          }
        });
      } else {
        sectorUserNew({ join_id, id: this.curNode.id }).then(res => {
          if (res.data.success) {
            this.$message({
              type: 'success',
              message: '添加成功',
            });
            //刷新逻辑
            this.getData(this.curNode);
          }
        });
      }
    },
    handleConfirm() {
      //确定表单
      let callback = node => {
        this.getData(node);
        this.dialogVisible = false;
      };
      let handler = {
        add: () => this.handleAddSection(callback),
        addsub: () => this.handleAddSection(callback),
        edt: () => this.handleEdtSection(callback),
        edtmem: () => this.handleEdtMember(callback),
      };
      handler[this.formType]();
    },
    pickitMem(data) {
      console.log(data);
      let arr = this.sectionForm.admin.map(v => v.id);
      if (arr.includes(data.id)) {
        let newAdmin = this.sectionForm.admin.filter(v => v.id !== data.id);
        this.$set(this.sectionForm, 'admin', newAdmin);
        console.log('newAdmin', newAdmin);
      } else {
        this.sectionForm.admin.push(data);
      }
    },
    getData(node) {
      if (this.type == 'menber') {
        //传了node则注重更新当某个node，右边的tableData
        sectorLis({ keyword: '' }).then(res => {
          this.partTree = res.data.data.map(v => {
            v.deep = 0;
            return v;
          });
          if (node) {
            let exnode = node;
            this.deepVisit(res.data.data, 'subsector', cbnode => {
              if (cbnode.id == node.id) {
                exnode = cbnode;
              }
            });
            this.handleNodeClick(exnode);
            return;
          }
          let belong = '';
          this.deepVisit(this.partTree, 'subsector', (node, i, arr) => {
            if (belong != '') arr[i].belong = belong;
            belong = node.id;
          });
          this.handleNodeClick(this.partTree[0]);
          this.getSectionLis(this.partTree);
        });
      } else if (this.type == 'role') {
        sectorRoleLis({ keyword: '' }).then(res => {
          //变换下结构
          let data = res.data.data[0];
          let obj = {};
          obj.deep = 0;
          obj.id = 0;
          obj.name = data.name;
          obj.udata = data.s_users.reverse();
          obj.subsector = [];
          this.partTree = [obj];
          // if (node) {
          //   let exnode = node;
          //   this.deepVisit(res.data.data, 'subsector', cbnode => {
          //     if (cbnode.id == node.id) {
          //       exnode = cbnode;
          //     }
          //   });
          //   this.handleNodeClick(exnode);
          //   return;
          // }
          this.handleNodeClick(this.partTree[0]);
          sectorLis({ keyword: '' }).then(res => {
            this.getSectionLis(res.data.data);
          });
        });
      }
    },
    getSectionLis(tree) {
      //遍历该tree(arr),返回sectorLis,扁平化处理
      this.sectorLis = [];
      this.deepVisit(tree, 'subsector', node => {
        console.log(node,'node')
        let obj = { id: node.id, label: node.name };
        if(node.subsector == undefined){
          this.sectorLis.push(obj);
        }
        
      });
      console.log(this.sectorLis,'=======hahahah');
    },
    deepVisit(tree, prop, callback = null, callback2 = callback) {
      tree.forEach((v, i, arr) => {
        if (v[prop]) {
          callback ? callback(v, i, arr) : null;
          this.deepVisit(v[prop], callback, callback2);
        } else {
          callback2 ? callback2(v, i, arr) : null;
        }
      });
    },
    // addMember() {
    //   //添加成员前初始化
    //   this.showMemberAdd = !this.showMemberAdd;
    // },
    filterNode(value, data) {
      //过滤方法
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      //点击el-tree的方法
      console.log(data, data.udata);
      this.isRootNode = data.deep == 0;
      this.tableData = data.udata.map(v=>{ 
        v['sname'] = data.name 
        if(!this.isRootNode){
          v['seclis'] = [data.id]
        }
        
        return v
      })
      console.log(this.tableData)
      this.tableTitle = data.name;
      this.curNode = data;
      this.$nextTick(() => {
        //展开逻辑
        // console.log(this.$refs['tree'].getNode(data.id));
        if (this.$refs['tree'].getNode(data.id)) {
          this.$refs['tree'].setCurrentKey(data);
          this.$refs['tree'].store.nodesMap[data.id].expanded = true;
          this.$refs['tree'].store.nodesMap[data.id].checked = true;
        }
      });
    },
    handleCurbtn(type) {
      if (type == this.curbtn) {
        this.curbtn = '';
      } else {
        this.curbtn = type;
      }
    },
    lastName(name) {
      if (name) {
        return lastName(name);
      } else {
        return '';
      }
    },
    openDialog(formType) {
      const titleMap = {
        add: '添加部门',
        addsub: '添加子部门',
        edt: '编辑部门',
        edtmem: '修改成员信息',
      };
      this.sectionFormTitle = titleMap[formType];
      this.formType = formType;
      this.dialogVisible = true;
    },
    closeDialog() {
      //关闭弹窗初始化数据
      this.$set(this, 'sectionForm', {
        id: '',
        name: '', //姓名
        title: '', //部门名称
        phone: '', //手机号
        maid:'',
        admin: [],
        belong: '',
        mem_id: '', //成员信息
        mem_name: '',
        mem_phone: '',
        mem_belong: this.isRootNode ? [] : '',
      });
    },
    handleAddSection(callback) {
      //添加部门
      let subData = {
        name: this.sectionForm.title,
        // section_id: (this.sectionForm.admin && this.sectionForm.admin.id) || '',
        section_id: this.sectionForm.admin.map(v => Number(v.id)) || [],
        is_subsector: '1',
        id: '1',
      };
      if (this.formType == 'addsub') {
        // subData[is_subsector] = 1
        this.sectionForm.belong != ''
          ? (subData.id = this.sectionForm.belong)
          : this.$message({
              type: 'error',
              message: '所属部门不能为空',
            });
      }
      sectorNew(subData).then(res => {
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: '添加成功',
          });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg,
          });
        }
        callback ? callback() : null;
      });
    },
    handleInitEdt(data) {
      this.sectionForm.id = data.id;
      this.sectionForm.belong = data.belong;
      this.sectionForm.title = data.name;
      this.sectionForm.admin = this.member.filter(v => {
        v['old'] = true
        return data.section_id.includes(Number(v.id));
      });
      this.sectionForm.oldAdmin = JSON.parse(JSON.stringify(this.sectionForm.admin))
      this.openDialog('edt');
    },
    handleInitEdtMember(data) {
      console.log(data);
      this.sectionForm.mem_id = data.id;
      this.sectionForm.mem_name = data.name;
      this.sectionForm.mem_phone = data.phone;
      this.sectionForm.mem_belong = data.seclis;
      if (this.type == 'role') {
        this.sectionForm.mem_id = data.user_id;
      }
      if(!this.isRootNode){ // 单个部门里的成员编辑
        this.sectionForm.mem_belong = data.sid
        this.sectionForm.maid = data.maid
      }
      console.log(this.sectionForm);
      this.openDialog('edtmem');
    },
    handleEdtMember(callback) {
      //编辑成员信息
      let subData = {
        user_id: this.sectionForm.mem_id,
        phone: this.sectionForm.mem_phone,
      };
      let url = ''
      if(this.isRootNode){
        url = sectorRoleMod
        subData.uname = this.sectionForm.mem_name
      }else{
        url = sectorUserMod
        subData.user_name = this.sectionForm.mem_name
        subData.id =  this.sectionForm.mem_belong
        subData.maid =  this.sectionForm.maid 
        subData.change = this.is_edit_dep ? '1' : '0'
      }
      
      url(subData).then(res => {

        if (res.data.success) {
          this.$message({
            type: 'success',
            message: '编辑成功',
          });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg,
          });
        }
        this.is_edit_dep = false
        callback ? callback(this.curNode) : null;
      });
    },
    handleEdtSection(callback) {
      //编辑部门
      const arr = this.sectionForm.admin.filter(v=>{ if(v.old) return v})
      const new_arr = arr.map(v=> Number(v.id))
      const old_arr = this.sectionForm.oldAdmin.map(v=> Number(v.id))
      let del_ids = []
      if(new_arr.length != old_arr.length){
        del_ids = old_arr.filter(t=>{
            if(!new_arr.includes(t)){
              return t
            }
          })
      }
      
      let subData = {
        name: this.sectionForm.title,
        // section_id: (this.sectionForm.admin && this.sectionForm.admin.id) || '',
        section_id: this.sectionForm.admin.map(v => Number(v.id)) || [],
        del_section_id:del_ids,
        is_subsector: '1',
        id: this.sectionForm.id,
      };
      sectorMod(subData).then(res => {
        console.log(res);
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: '编辑成功',
          });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg,
          });
        }
        callback ? callback() : null;
      });
    },
    handleDelSection(id) {
      //删除部门
      sectorDel({ id }).then(res => {
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          //刷新逻辑
          this.getData();
        }
      });
    },
    //角色
    handleDelRole(row) {
      let subData = { user_id: row.user_id };
      sectorRoleDel(subData).then(res => {
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          //刷新逻辑
          this.getData(this.curNode);
        }
      });
    },
    // addRole() {
    //   //添加管理员
    // },
    handleAddRolePickit(item) {
      let join_id = item.id;
      console.log(this.currMember, join_id, this.currMember.includes(join_id));
      if (this.currMember.includes(join_id)) {
        this.handleDelRole({ user_id: join_id });
      } else {
        sectorRoleAdd({ user_id: join_id }).then(res => {
          console.log(res);
          if (res.data.success) {
            this.$message({
              type: 'success',
              message: '添加成功',
            });
            //刷新逻辑
            this.handleInitEdtMember(item)
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg,
            });
          }
          this.getData(this.curNode);
        });
      }
    },
  },
  components: {
    Member,
  },
  watch: {
    partSearch: {
      handler(val) {
        this.$refs.tree.filter(val);
      },
    },
  },
};
</script>
<style lang="less"></style>
