<!-- 设置页组件 -->
<template>
  <div class="popup setting" @click.self="setup">
    <div class="frame" :style="smartWidth" @click="$event.stopPropagation()" v-if="!showWechatIframe">
      <header>
        <span
          class="setting-ico ico"
          :style="{ backgroundImage: 'url(' + require(`assets/image/sidebar/${type}.png`) + ')' }"
        ></span>
        <span class="head-title md-font">{{ title }}</span>
        <span class="close-ico ico" @click="setup" />
      </header>
      <component v-on="$listeners" v-bind="$attrs" :type="type" :is="type" @setData="setDataByChild"> </component>
      <!-- <div v-if="type === 'account'"> -->
    </div>
    <template>
      <el-dialog width="488px" title="微信扫码绑定" append-to-body center :visible.sync="showWechatIframe">
        <wxlogin class="wxlogin" appid="wxdccac946bdfc5ee3" scope="snsapi_login" redirect_uri="https://p.eai9.com">
        </wxlogin>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import account from './account';
import menber from './menber';
import skin from './skin';
import wxlogin from 'vue-wxlogin';
import {} from '@/service/help';
export default {
  components: {
    wxlogin,
    account,
    skin,
    menber,
    role: menber,
  },
  data() {
    return {
      title: '账号设置',
      showWechatIframe: false,
    };
  },
  props: {
    type: {
      type: String,
      default: 'account', //account
    },
  },
  computed: {
    smartWidth() {
      return this.type == 'role' || this.type == 'menber' ? 'width:90%' : '';
    },
  },
  methods: {
    setDataByChild({ type, data }) {
      console.log(type, data )
      //子组件修改父组件属性的通道
      this.$set(this, type, data);
    },
    setup() {
      //设置完成 退出方法
      this.$emit('setup', '');
    },
  },
  // mounted() {
  // },
  watch: {
    type: {
      handler(val) {
        let titleMap = {
          account: {
            title: '账户设置',
          },
          skin: {
            title: '皮肤设置',
          },
          menber: {
            title: '成员管理',
          },
          role: {
            title: '角色管理',
          },
        };
        this.title = titleMap[val].title;
        // titleMap[val].init ? titleMap[val].init() : null;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less">
@import url('~@/assets/style/sidebar/setting.less');
.wxlogin {
  width: 300px;
  height: 340px;
  overflow: hidden;
  margin: auto;
}
.logInWechat {
  background-color: #fff;
  text-align: center;
  height: 40px;
  margin-bottom: -40px;
  line-height: 40px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*定义滑块 内阴影+圆角*/
</style>
