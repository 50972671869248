<template>
  <div class="set-main skin">
    <ul class="skin-ul">
      <li
        v-for="(item, index) in skin"
        :key="item.key"
        :class="{ 'active-skin': active_skin === index }"
        @click="selectSkin(index)"
      >
        <div>
          <div v-if="active_skin === index" class="ico-select"></div>
          <img class="skin-img" :src="item.img" :alt="item.name" />
        </div>
        <div class="skin-name">{{ item.name }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    bgBli: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      skin: [
        {
          key: 'default',
          name: '默认',
          img: require('@/assets/image/sidebar/skin/default.png'),
        },
        {
          key: 'sky_blue',
          name: '天蓝',
          img: require('@/assets/image/sidebar/skin/sky_blue.png'),
        },
        {
          key: 'emerald_green',
          name: '翡绿',
          img: require('@/assets/image/sidebar/skin/emerald_green.png'),
        },
        {
          key: 'tangor',
          name: '橘橙',
          img: require('@/assets/image/sidebar/skin/tangor.png'),
        },
        {
          key: 'bright_red',
          name: '艳红',
          img: require('@/assets/image/sidebar/skin/bright_red.png'),
        },
        {
          key: 'violet',
          name: '紫色',
          img: require('@/assets/image/sidebar/skin/violet.png'),
        },
        {
          key: 'dark_blue',
          name: '暗蓝',
          img: require('@/assets/image/sidebar/skin/dark_blue.png'),
        },
        {
          key: 'lake',
          name: '湖泊',
          img: require('@/assets/image/sidebar/skin/lake.png'),
        },
        {
          key: 'mountains_and_rivers',
          name: '山川',
          img: require('@/assets/image/sidebar/skin/mountains_and_rivers.png'),
        },
        {
          key: 'snow',
          name: '雪地',
          img: require('@/assets/image/sidebar/skin/snow.png'),
        },
      ],
      active_skin: '',
    };
  },
  methods: {
    selectSkin(i) {
      this.$emit('skinChange', i);
    },
  },
  watch: {
    bgBli: {
      handler(val) {
        this.active_skin = val;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="less"></style>
