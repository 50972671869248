<template>
  <div class="login">
    <div class="logo" :class="{ 'wx-show': boxState == 'wechat' }"></div>
    <div class="mainbox formbox" v-show="boxState == 'form'">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="账号密码登录" name="acount_pass"> </el-tab-pane>
        <el-tab-pane label="手机验证码登录" name="phone_code"> </el-tab-pane>
      </el-tabs>
      <div class="pane">
        <form autocomplete="off">
          <template v-if="activeName == 'acount_pass'">
            <input
              type="text"
              placeholder="请输入账号"
              v-model="account"
              @keydown="keyLogin($event)"
              @focus="inputActive = '1'"
              ref="account"
            />
            <div class="nd-input pass-input">
              <input
                :type="[canlook ? 'text' : 'password']"
                placeholder="请输入用密码"
                v-model="pass"
                @focus="inputActive = '2'"
                @keydown="keyLogin($event)"
                ref="pass"
                autocomplete=""
              />
              <div
                class="eye"
                :class="{ 'look-icon': canlook, 'noLook-icon': !canlook }"
                @click="canlook = !canlook"
              ></div>
            </div>
          </template>
          <template v-else>
            <input
              type="text"
              placeholder="请输入手机号"
              v-model="account"
              autocomplete="off"
              @focus="inputActive = '1'"
              @keydown="keyLogin($event)"
              ref="phone"
            />
            <div class="nd-input code-input">
              <input
                type="text"
                placeholder="请输入验证码"
                v-model="code"
                autocomplete="off"
                @keydown="keyLogin($event)"
                @focus="inputActive = '2'"
                ref="code"
              />
              <div class="code" @click="getCode" v-if="showGetCode">获取验证码</div>
              <div class="code" v-else>{{ this.codeTime }}</div>
            </div>
          </template>
          <button type="button" @click="login">登录</button>
          <div class="forget" @click="boxState = 'forget'">忘记密码？</div>
          <div class="line-box">
            <div class="line"></div>
            <div class="line-text">第三方登录</div>
            <div class="line"></div>
          </div>
          <img src="../../../assets/image/sidebar/wechat.png" width="40px" @click="toWechat" />
        </form>

        <div class="service">登录即表示您已阅读并同意<span style="color: #4880ff">服务条款</span></div>
      </div>
    </div>
    <div class="mainbox formbox forget-box" v-show="boxState == 'forget'">
      <div class="close-box">
        <img src="../../../assets/image/sidebar/login_close.png" @click="boxState = 'form'" width="48px" />
      </div>
      <div class="title">重置密码</div>
      <input
        type="text"
        placeholder="请输入手机号"
        v-model="account"
        @keydown="keyLogin($event)"
        @focus="inputActive = '1'"
        ref="phoneforget"
      />
      <div class="nd-input code-input">
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="code"
          @keydown="keyLogin($event)"
          @focus="inputActive = '2'"
          ref="codeforget"
          autocomplete=""
        />
        <div class="code" @click="getCode" v-if="showGetCodeFoget">获取验证码</div>
        <div class="code" v-else>{{ this.codeTimeFoget }}</div>
      </div>
      <div class="nd-input pass-input">
        <input
          :type="[canlook ? 'text' : 'password']"
          placeholder="请输入用密码"
          v-model="pass"
          @focus="inputActive = '3'"
          @keydown="keyLogin($event)"
          ref="password"
          autocomplete=""
        />
        <div class="eye" :class="{ 'look-icon': canlook, 'noLook-icon': !canlook }" @click="canlook = !canlook"></div>
      </div>
      <button type="button" @click="resetPass">重置密码</button>
    </div>
    <div class="mainbox wx" v-if="boxState == 'wechat'">
      <div class="qrcode-box">
        <div class="close-box">
          <img src="../../../assets/image/sidebar/login_close.png" @click="boxState = 'form'" width="48px" />
        </div>
        <wxlogin class="iframe" appid="wxdccac946bdfc5ee3" scope="snsapi_login" redirect_uri="https://p.eai9.com">
        </wxlogin>
      </div>
      <div class="service wechat-foot">登录即表示您已阅读并同意<span style="color: #4880ff">服务条款</span></div>
    </div>
    <div class="keep-on-record">
      <div class="content">
        Copyright@2016 www.eai9.com 医键通版权所有
        <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备 16077822号</a>
      </div>
    </div>
  </div>
</template>

<script>
import wxlogin from 'vue-wxlogin';
import { postSms, postPasswordMod } from '@/service/help.js';
export default {
  name: 'login',
  data() {
    return {
      activeName: 'acount_pass',
      // activeName: 'phone_code',
      boxState: 'form',
      account: '',
      pass: '',
      code: '', //验证码
      inputActive: 0,
      canlook: false,
      showGetCode: true,
      showGetCodeFoget: true,
      codeTime: 0,
      codeTimeFoget: 0,
      wcloginUrl:
        'https://open.weixin.qq.com/connect/qrconnect?appid=wxdccac946bdfc5ee3&redirect_uri=https://p.eai9.com&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect',
    };
  },
  components: {
    wxlogin,
  },
  created() {
    // const s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = 'http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    // document.body.appendChild(s);
  },
  mounted() {},
  methods: {
    login: function () {
      let loginInfo = { account: this.account, password: this.pass, type: 'ap' };
      if (this.activeName == 'phone_code') {
        loginInfo.sms = this.code;
      }
      console.log(loginInfo);
      this.$store.dispatch('login', loginInfo).then(res => {
        if (res.success) {
          this.$emit('login', res.success);
        } else {
          this.$message({
            type: 'error',
            message: '账户或密码错误',
          });
          this.$refs.account.focus();
        }
      });
    },
    keyLogin(event) {
      if (event.keyCode === 13) {
        if (this.activeName == 'acount_pass') {
          this.login();
        } else if (this.activeName == 'phone_code') {
          console.log('手机验证码登录');
        }
      }
      let inputArr = [];
      if (this.boxState == 'forget') {
        inputArr = ['phoneforget', 'codeforget', 'password'];
      } else if (this.activeName == 'acount_pass') {
        inputArr = ['account', 'pass'];
      } else if (this.activeName == 'phone_code') {
        inputArr = ['phone', 'code'];
      }
      let refkey = '';
      if (event.keyCode === 40 && this.inputActive < inputArr.length) {
        //向下
        refkey = inputArr[this.inputActive];
        this.$refs[refkey].focus();
      } else if (event.keyCode === 38 && this.inputActive > 1) {
        //向上
        refkey = inputArr[this.inputActive - 2];
        this.$refs[refkey].focus();
      }
    },
    toWechat() {
      //微信登录方法
      this.boxState = 'wechat';
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.inputActive = 0;
    },
    getCode() {
      let opt = this.boxState == 'forget' ? 'modpassword' : 'login';
      postSms({ opt: opt, account: this.account }).then(
        r => {
          console.log(r);
          if (r.data.msg == '成功') {
            this.$message({
              type: 'success',
              message: '验证码发送成功，请在手机上查收',
            });
            this.boxState == 'forget' ? (this.showGetCodeFoget = false) : (this.showGetCode = false);
            let countDown = 60;
            let interval = setInterval(() => {
              countDown--;
              this.boxState == 'forget' ? (this.codeTimeFoget = countDown) : (this.codeTime = countDown);
              if (countDown <= 0) {
                this.boxState == 'forget' ? (this.showGetCodeFoget = true) : (this.showGetCode = true);
                clearInterval(interval);
              }
            }, 1000);
          } else {
            this.$message({
              type: 'error',
              message: r.data.msg,
            });
          }
        },
        () => {
          this.$message({
            type: 'error',
            message: '发生未知错误',
          });
          this.showGetCode = true;
        },
      );
    },
    resetPass() {
      postPasswordMod({ sms: this.code, password: this.pass, account: this.account }).then(
        r => {
          if (r.data.msg == '成功') {
            this.$message({
              type: 'success',
              message: '密码重置成功',
            });
          } else {
            this.$message({
              type: r.data.msg,
              message: '发生未知错误',
            });
          }
        },
        () => {
          this.$message({
            type: 'error',
            message: '发生未知错误',
          });
        },
      );
    },
  },
};
</script>

<style lang="less">
@import url('~@/assets/style/sidebar/login.less');
</style>
