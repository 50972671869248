<template>
  <div>
    <div class="main">
      <div class="head">
        <h4>您有{{ message.length }}条新的提醒</h4>
        <img
          :src="close1"
          @click="closeAll()"
          alt=""
          class="close"
          v-show="!changeColor"
          @mouseenter="changeColor = !changeColor"
          style="cursor:pointer;"
        />
        <img
          :src="close2"
          @click="closeAll()"
          alt=""
          class="close"
          v-show="changeColor"
          @mouseleave="changeColor = !changeColor"
          style="cursor:pointer;"
        />
      </div>
      <div class="body">
        <ul>
          <li v-for="(item, index) in message" :key="index">
            <div class="readcolor"></div>
            <div class="title">
              <div id="stime">{{ parseData1(item.stime) }}&#12288;{{ parseData2(item.stime) }}</div>
              <span class="length">{{ index + 1 }}/{{ message.length }}</span>
            </div>
            <div class="starandend">
              <span class="content">{{ item.content }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { postRemindRead } from '@/service/help';
export default {
  name: 'remindpopup',
  data() {
    return {
      showAll: false,
      changeColor: false,
      close1: require('@/assets/image/progress/addTask/close1.png'),
      close2: require('@/assets/image/progress/addTask/close2.png'),
      message: [
        {
          content: '',
          id: '',
          stime: '',
        },
      ],
    };
  },
  created() {
    this.message = [];
    setInterval(() => {
      postRemindRead({
        times: this.parseData(new Date()),
      }).then(res => {
        if (res.data.data.length > 0) {
          res.data.data.forEach(item => {
            this.message.push(item);
          });
          this.$emit('ctrlpopup', true);
        }
      });
    }, 3000);
  },
  methods: {
    closeAll() {
      this.$emit('ctrlpopup', this.showAll);
      this.message = [];
    },
    parseData(data) {
      if (typeof data === 'object') {
        let y = data.getFullYear();
        let m = data.getMonth() + 1;
        let d = data.getDate();
        let h = data.getHours();
        let mi = data.getMinutes();
        let s = data.getSeconds();
        m = m < 10 ? '0' + m : m;
        d = d < 10 ? '0' + d : d;
        h = h < 10 ? '0' + h : h;
        mi = mi < 10 ? '0' + mi : mi;
        s = s < 10 ? '0' + s : s;
        return y + '-' + m + '-' + d + ' ' + h + ':' + mi + ':' + s;
      } else return '';
    },
    parseData1(time) {
      if (time === '') return '';
      else return time.substr(11, 5);
    },
    parseData2(time) {
      if (time === '') return '';
      else return time.substr(5, 2) + '月' + time.substr(8, 2) + '日';
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 364px;
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(202, 212, 230, 0.55);
  border-radius: 10px;
  position: fixed;
  right: 32px;
  top: 100px;
  max-height: 370px;
  overflow: hidden;
  z-index: 100;
  .head {
    height: 40px;
    background: #ffc108;
    border-radius: 10px 10px 0px 0px;
    h4 {
      float: left;
      margin: 10px 0 0px 16px;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
    }
    img {
      float: right;
      margin: 15px;
    }
  }
  .body {
    ul {
      list-style: none;
      margin: 0 10px 0 10px;
      padding-bottom: 10px;
      li {
        display: block;
        width: 344px;
        height: 96px;
        background: #f8fbff;
        border-radius: 4px;
        margin-top: 10px;
        overflow: auto;
        .readcolor {
          width: 14px;
          height: 14px;
          background: #ffc108;
          border-radius: 3px;
          float: left;
          margin: 11px 0 0 10px;
        }
        .title {
          width: 300px;
          height: 20px;
          float: left;
          margin: 6px 0 0 3px;
          .stime {
            width: 110px;
            height: 20px;
            overflow: hidden;
            float: left;
          }
          .length {
            float: right;
          }
        }
        .starandend {
          float: left;
          margin: 28px 0 0 9px;
          span {
            margin-left: 9px;
          }
          .content {
            font-size: 22px;
          }
        }
      }
    }
  }
}
</style>
