<template>
  <div class="set-main account">
    <div class="ac-box" :class="item.key" v-for="(item, index) in acSet" :key="item.key">
      <div class="ac-title main-font">
        {{ item.name }}
        <div class="open" v-if="item.show === true" @click="setFold(index)">
          收起
          <i class="el-icon-caret-top" />
        </div>
        <div class="hide" v-else-if="item.show === false" @click="setFold(index)">
          展开
          <i class="el-icon-caret-bottom" />
        </div>
      </div>
      <div class="mi-font" v-html="item.summary" v-if="item.summary !== ''"></div>
      <div class="ac-content" v-if="item.show || item.show === undefined">
        <div v-if="item.type === 0" class="set-content-box st0" :class="item.key + '-box'">
          <div class="portrait" v-if="item.value === ''">
            <div class="head-draw port-b">{{ account }}</div>
            <div class="head-draw port-m">{{ account }}</div>
            <div class="head-draw port-s">{{ account }}</div>
          </div>
          <div class="portrait" v-else>
            <div class="port-b">
              <img :src="item.value" />
            </div>
            <div class="port-m">
              <img :src="item.value" />
            </div>
            <div class="port-s">
              <img :src="item.value" />
            </div>
          </div>
          <div class="set-box">
            <div class="upload-button" @click="upload(false)">
              选择照片上传
              <input v-show="false" type="file" accept="image/*" ref="upload" @change="changeFile($event)" />
            </div>
            <div @click="upload(true)">恢复默认头像</div>
          </div>
        </div>
        <div v-else-if="item.type === 1" class="set-content-box st1" :class="item.key + '-box'">
          <div class="set-box">
            <div v-for="input in item.data" :key="input.key">
              <span class="xs-font">{{ input.title }}</span>
              <input type="text" v-model="input.val" :placeholder="input.placeholder" />
              <div class="mi-font tip">{{ input.tip }}</div>
            </div>
            <el-button type="primary" @click="setItemSave(item.key)">{{ item.btnName || '保存' }}</el-button>
          </div>
        </div>
        <div v-else-if="item.type === 2" class="set-content-box st2" :class="item.key + '-box'">
          <div class="set-box">
            <div v-for="input in item.data" :key="input.key">
              <input v-if="input.key !== 'code'" type="text" v-model="input.val" :placeholder="input.placeholder" />
              <div v-else class="code-input">
                <input type="text" v-model="input.val" :placeholder="input.placeholder" />
                <span v-if="canGetCode" class="getCodeSpan" @click="getCode">获取验证码</span>
                <span v-else class="codeTime">{{ codeTime }}</span>
              </div>
            </div>
            <div class="mi-font">{{ item.tip }}</div>
            <el-button type="primary" @click="setItemSave(item.key)">{{ item.btnName || '保存' }}</el-button>
          </div>
        </div>
        <div v-else-if="item.type === 3" class="set-content-box st3" :class="item.key + '-box'">
          <!-- 微信专门类型 -->
          <div class="set-box">
            <div>
              <img class="wechat-img" width="40px" src="../../../assets/image/sidebar/wechat.png" />
              <span v-if="item.val !== ''">已绑定，<a @click="wechatBind">点击解绑</a></span>
              <span v-else>未绑定，<a @click="wechatBind">点击绑定</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dataURLtoBlob, lastName } from '@/utils/utils';
import {
  postUserInfoLis,
  postUserInfoMod,
  uploadFile,
  postUserInfoPhoneChange,
  postUserInfoCheckSms,
  postUserinfoWxBind,
} from '@/service/help';
export default {
  props: {
    account: {
      type: String,
      default: '未知',
    },
  },
  data() {
    return {
      acSet: [
        //账号设置项目
        {
          name: '设置头像', //项目名
          summary: '选择一张个人正面作为头像，企业成员可以更容易认识你', //简介
          value: '', //头像url
          key: 'portrait',
          type: 0, //头像类型
        },
        {
          name: '个人资料', //项目名
          summary: '', //简介
          key: 'realname',
          type: 1, //填写形式为 标题：内容
          value: '',
          data: [
            {
              key: 'name',
              placeholder: '请输入姓名',
              title: '姓名',
              val: '',
              tip: '真实姓名，企业成员知道如何称呼你',
            },
          ],
        },
        {
          name: '修改用户名', //项目名
          show: true, //是否展示
          summary:
            '修改您在当前企业的用户名，用户名只能是英文、数字和下划线组成，且必须以字母开头，次用户名用于登录当前企业，或者其他在聊天中@你。', //简介
          key: 'name',
          type: 2,
          value: '',
          data: [
            {
              key: 'name',
              placeholder: '请输入用户名',
              val: '',
            },
          ],
        },
        {
          name: '修改密码', //项目名
          show: true, //是否展示
          summary: '', //简介
          key: 'password',
          type: 2,
          tip: '密码由数字和字母组合，且长度不少于6', //提示语
          data: [
            {
              key: 'oldPass',
              placeholder: '旧密码',
              val: '',
            },
            {
              key: 'newPass',
              placeholder: '新密码',
              val: '',
            },
          ],
        },
        {
          name: '绑定手机', //项目名
          show: true, //是否展示
          summary:
            '你当前的手机号码是<span style="color:#FF6057">12345678900</span>，修改手机时系统会发送验证码到新的手机号码上，然后输入验证码后完成修改。', //简介
          key: 'phone',
          type: 2,
          btnName: '绑定', //保存按钮名字
          data: [
            {
              key: 'number',
              placeholder: '请输入手机号',
              val: '',
            },
            {
              key: 'code',
              placeholder: '请输入验证码',
              val: '',
            },
          ],
        },
        {
          name: '绑定微信', //项目名
          show: true, //是否展示
          type: 3,
          summary: '', //简介
          val: '',
          key: 'wechat',
        },
      ],
      canGetCode: true, //是否可以获取二维码
      codeTime: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      postUserInfoLis().then(r => {
        console.log(r.data.data);
        let { avatar, account, name, realname, wechat_unionid } = r.data.data;
        this.setAcItem(this.acSet, 'portrait', 'value', avatar);
        this.setAcItem(this.acSet, 'phone', 'summary', account);
        this.setAcItem(this.acSet, 'realname', 'data', realname, 'name', 'val');
        this.setAcItem(this.acSet, 'name', 'data', name, 'name', 'val');
        this.setAcItem(this.acSet, 'wechat', 'val', wechat_unionid);
      });
    },
    upload(reset = false) {
      //选择照片上传方法
      //   console.log(this.$refs.upload);
      if (!reset) {
        this.$refs.upload[0].click();
      } else {
        this.userInfoMod('avatar', '', '', '头像重置成功');
      }
    },
    setFold(i) {
      //控制展开 折叠
      let s = !this.acSet[i].show;
      this.$set(this.acSet[i], 'show', s);
    },
    setItemSave(k) {
      //各项的保存方法
      console.log(k);
      let arr = this.acSet;
      let i = this.getIndexByKey(arr, k);
      let subi;
      let v;
      if (k === 'realname') {
        v = arr[i].data[0].val; //姓名的
        this.userInfoMod(k, v);
      } else if (k === 'name') {
        v = arr[i].data[0].val; //用户名的
        this.userInfoMod(k, v);
      } else if (k === 'password') {
        subi = this.getIndexByKey(arr[i].data, 'newPass');
        v = arr[i].data[subi].val; //新密码
        let oldPass = arr[i].data[this.getIndexByKey(arr[i].data, 'oldPass')].val;
        this.userInfoMod(k, v, oldPass);
      } else if (k === 'phone') {
        subi = this.getIndexByKey(arr[i].data, 'code');
        v = arr[i].data[subi].val; //验证码
        this.userInfoMod(k, v);
      }
    },
    getIndexByKey(arr, k) {
      //通过key查找设置项的下标
      let index = -1;
      arr.forEach((v, i) => {
        if (v.key === k) {
          index = i;
        }
      });
      return index;
    },
    setAcItem(arr, k, sub_key, v, data_key = '', subMod = 'val') {
      //修改某项数据
      let i = this.getIndexByKey(arr, k);
      if (sub_key === 'data') {
        //data要多查找一层
        let subI = this.getIndexByKey(arr[i].data, data_key);
        this.$set(arr[i].data[subI], subMod, v);
      } else if (sub_key === 'summary') {
        //summary字段修改要变下
        if (k === 'phone') {
          console.log(v);
          v = `你当前的手机号码是<span style="color:#FF6057">${v}</span>`;
          this.$set(arr[i], sub_key, v);
        }
      } else {
        //一般第一层修改
        this.$set(arr[i], sub_key, v);
      }
    },
    userInfoMod(k, v, oldpass = '', explain = '') {
      //页面修改用户信息的方法
      // explain字段预留用于说明某些情况
      let fd = new FormData();

      let message = '修改成功';
      if (k === 'phone') {
        fd.append('code', v);
        postUserInfoCheckSms(fd).then(r => {
          let i = this.getIndexByKey(this.acSet, 'phone');
          let i2 = this.getIndexByKey(this.acSet[i].data, 'number');
          let val = this.acSet[i].data[i2].val;
          this.$message({
            type: 'success',
            message: message,
          });
          this.setAcItem(this.acSet, 'phone', 'summary', val);
        });
      } else {
        fd.append('key', k);
        fd.append('value', v);
        if (oldpass !== '') {
          //修改密码时要多传一个
          fd.append('old_password', oldpass);
        }
        postUserInfoMod(fd)
          .then(res => {
            let { data, success } = res.data;
            if (success) {
              if (k === 'avatar') {
                //修改头像的情况
                if (success) {
                  //上传成功
                  this.setAcItem(this.acSet, 'portrait', 'value', v);
                  message = '上传修改成功';
                  if (explain !== '') {
                    message = explain;
                  }
                }
                let userInfo = this.$store.state.userInfo;
                userInfo.avatar = v;
                this.$store.commit('set', { type: 'userInfo', data: userInfo });
              } else if (k === 'name') {
                //修改用户名那栏
                this.setAcItem(this.acSet, 'name', 'data', v, 'name', 'val');
              } else if (k === 'realname') {
                //修改真实姓名那栏
                this.setAcItem(this.acSet, 'realname', 'data', v, 'name', 'val');
              }
              this.$message({
                type: 'success',
                message: message,
              });
            } else {
              this.$message({
                type: 'error',
                message: '修改失败',
              });
            }
          })
          .catch(e => {
            console.log(e);
            this.$message({
              type: 'error',
              message: '发生未知错误',
            });
          });
      }
    },
    changeFile(e) {
      //选择文件方法
      let a = e.target.value;
      let name = a.slice(a.lastIndexOf('\\') + 1);
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      let _this = this;
      reader.onload = function (e) {
        //这里的this指向函数
        _this.file = this.result;
        let fd = new FormData();
        let blob = dataURLtoBlob(e.target.result); //转blob
        if (blob.size > 10485760) {
          alert('所传文件不能大于10M');
          return false;
        }
        let f = new File([blob], name, { type: blob.type });
        fd.append('uploadfile', f); //数据
        let size = blob.size / 1024;
        if (size > 1024) {
          size = (size / 1024).toFixed(2) + 'm';
        } else {
          size = size.toFixed(2) + 'k';
        }
        uploadFile(fd).then(res => {
          let url = res.data.data;
          console.log(url);
          _this.userInfoMod('avatar', url);
        });
      };
      this.showUpLoad = false;
    },
    getCode() {
      // 获取验证码方法
      let fd = new FormData();
      let i = this.getIndexByKey(this.acSet, 'phone');
      let i2 = this.getIndexByKey(this.acSet[i].data, 'number');
      let val = this.acSet[i].data[i2].val;
      fd.append('account', val);
      postUserInfoPhoneChange(fd).then(r => {
        if (r.data.success) {
          this.$message({
            type: 'success',
            message: '验证码发送成功',
          });
          this.canGetCode = false;
          this.getCodeInterval();
        } else {
          this.$message({
            type: 'error',
            message: '非法手机号',
          });
        }
      });
    },
    getCodeInterval() {
      this.codeTime = 60;
      let interval = setInterval(() => {
        this.codeTime--;
        if (this.codeTime <= 0) {
          this.canGetCode = true;
          clearInterval(interval);
        }
      }, 1000);
    },
    wechatBind() {
      //微信绑定方法
      let i = this.getIndexByKey(this.acSet, 'wechat');
      let val = this.acSet[i].val;
      if (val !== '') {
        //已绑定，点击解绑
        postUserinfoWxBind({ code: '-1' }).then(r => {
          this.$message({
            type: 'success',
            message: '解绑成功',
          });
          this.setAcItem(this.acSet, 'wechat', 'val', '');
        });
      } else {
        //未绑定，点击绑定
        console.log('绑定');
        this.$emit('setData', { type: 'showWechatIframe', data: true });
        this.setAcItem(this.acSet, 'wechat', !val);
      }
    },
  },
};
</script>
<style lang="less"></style>
