<template>
  <div>
    <div class="bg" v-show="showAll" @click="ctrlshow"></div>
    <div class="main" v-show="showAll">
      <div class="leftnav">
        <ul>
          <li
            v-for="(item, index) in leftnav"
            :key="index"
            :class="{
              changeleftnavcolor: ChangeLeftNavColor === item.id,
            }"
            @click="LeftNavBtn(item.id)"
          >
            {{ item.str }}
          </li>
        </ul>
        <div id="add" @click="openNewRemind = true"><img :src="add" alt="" />新建</div>
      </div>
      <div class="message">
        <div class="messagehead">
          <div
            @click="read = 0"
            v-show="ChangeLeftNavColor === 1"
            class="topnav"
            :class="{ topnavblueline: read === 0 }"
          >
            未读({{ unread.length }})
          </div>
          <div
            @click="read = 1"
            v-show="ChangeLeftNavColor === 1"
            class="topnav"
            :class="{ topnavblueline: read === 1 }"
          >
            已读({{ reads.length }})
          </div>
          <div v-show="ChangeLeftNavColor === 2" class="topnav topnavblueline">提醒列表({{ message.length }})</div>
          <div class="readall" v-show="ChangeLeftNavColor === 1 && read === 0">
            <img
              :src="readAll1"
              v-show="!ChangeReadAllColor"
              @mouseenter="ChangeReadAllColor = !ChangeReadAllColor"
              alt=""
              style="cursor:pointer;"
            />
            <img
              :src="readAll2"
              v-show="ChangeReadAllColor"
              @mouseleave="ChangeReadAllColor = !ChangeReadAllColor"
              alt=""
              style="cursor:pointer;"
              @click="readAll"
            />
          </div>
        </div>
        <div class="messagebody">
          <ul v-show="ChangeLeftNavColor === 1 && read === 0" class="read">
            <li v-for="(item, index) in unread" :key="index">
              <div class="readcolor"></div>
              <div class="title">
                <div class="stime">{{ parseData(item.stime) }}&#12288;{{ parseData2(item.stime) }}</div>
                <span class="length">{{ index + 1 }}/{{ unread.length }}</span>
              </div>
              <div class="starandend">
                <span class="content">{{ item.content }}</span>
              </div>
            </li>
          </ul>

          <ul v-show="ChangeLeftNavColor === 1 && read === 1" class="read">
            <li v-for="(item, index) in reads" :key="index">
              <div class="readcolor2"></div>
              <div class="title">
                <div class="stime">{{ parseData(item.stime) }}&#12288;{{ parseData2(item.stime) }}</div>
                <span class="length">{{ index + 1 }}/{{ reads.length }}</span>
              </div>
              <div class="starandend">
                <span class="content">{{ item.content }}</span>
              </div>
            </li>
          </ul>

          <div class="remindlist" v-show="ChangeLeftNavColor === 2">
            <div class="messagetable">
              <table>
                <tr class="tableheadrow">
                  <th class="remindcontent">提醒内容</th>
                  <th class="creattime">提醒时间</th>
                  <th class="todo">操作</th>
                </tr>
                <tr v-for="(item, index) in message" :key="index" class="tablebodyrow">
                  <td class="remindconcretecontent">
                    <div>{{ item.content }}</div>
                  </td>
                  <td class="creattime">
                    <span>{{ parseData(item.stime) }}</span
                    ><br />
                    <span>{{ parseData1(item.stime) }}</span>
                  </td>
                  <td class="icon-opera">
                    <div
                      class="ico pencil"
                      @click="(openEditRemind = true), (id = item.id), (content = item.content)"
                    />
                    <div class="ico trash" @click="removeHandle(index, item.id)" />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <newremind v-if="openNewRemind" @child-event="getFromSon" :a="openNewRemind"></newremind>
    <editremind v-if="openEditRemind" @ctrledit="getFromEdit" :id="id" :content="content"></editremind>
  </div>
</template>

<script>
import newremind from './newremind';
import editremind from './editremind';
import { getRemindLis, getRemindNotifyAllread, postRemindDel, postRemindNotifyLis } from '@/service/help';
export default {
  name: 'remindmessage',
  components: {
    newremind,
    editremind,
  },
  data() {
    return {
      openNewRemind: false,
      openEditRemind: false,
      ChangeLeftNavColor: 1,
      read: 0,
      add: require('@/assets/image/progress/remind/add.png'),
      readAll1: require('@/assets/image/progress/remind/readall1.png'),
      readAll2: require('@/assets/image/progress/remind/readall2.png'),
      ChangeReadAllColor: false,
      leftnav: [
        {
          str: '消息',
          id: 1,
        },
        {
          str: '提醒列表',
          id: 2,
        },
      ],
      message: [
        {
          content: '',
          id: '',
          stime: '',
        },
      ],
      reads: [
        {
          content: '',
          id: '',
          stime: '',
        },
      ],
      unread: [
        {
          content: '',
          id: '',
          stime: '',
        },
      ],
      id: '', //所选择要修改的id
      content: '', //所选择要修改的原先提醒内容
    };
  },
  props: ['showAll', 'isGetData'],
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getRemindLis().then(res => {
        let data = res.data.data;
        this.message = data;
      });
      postRemindNotifyLis({
        is_read: '0',
      }).then(res => {
        this.unread = res.data.data;
      });
      postRemindNotifyLis({
        is_read: '1',
      }).then(res => {
        this.reads = res.data.data;
      });
    },
    LeftNavBtn(id) {
      this.ChangeLeftNavColor = id;
    },
    getFromSon(data) {
      this.openNewRemind = data;
      this.getData();
    },
    getFromEdit(data) {
      this.openEditRemind = data;
      this.getData();
    },
    removeHandle(index, id) {
      postRemindDel({
        id: id,
      }).then(res => {
        if (res.data.success) {
          this.message.splice(index, 1);
        }
      });
    },
    readAll() {
      getRemindNotifyAllread().then(() => {
        this.getData();
      });
    },
    ctrlshow() {
      this.$emit('changeShow', false);
    },
    parseData(time) {
      if (time === '') return '';
      else return time.substr(11, 5);
    },
    parseData1(time) {
      if (time === '') return '';
      else return time.substr(0, 4) + '年' + time.substr(5, 2) + '月' + time.substr(8, 2) + '日';
    },
    parseData2(time) {
      if (time === '') return '';
      else return time.substr(5, 2) + '月' + time.substr(8, 2) + '日';
    },
  },
  watch: {
    isGetData: {
      handler(newVal) {
        if (newVal) {
          this.getData();
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  width: 4px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 3px;
  // background-color: transparent;
  background-color: #eff3f6;
  height: 489px;
  margin: 10px 0 10px 0;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: none;
  background-color: #c2c7d9;
}
.bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.main {
  color: #334681;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  width: 660px;
  height: 560px;
  border-radius: 10px;
  background-color: #ffffff;
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  .leftnav {
    background: #f2f9ff;
    width: 110px;
    height: 560px;
    border-radius: 10px 0px 0px 10px;
    padding-top: 51px;
    float: left;

    ul {
      list-style: none;
      li {
        display: inline-block;
        width: 110px;
        height: 60px;
        line-height: 60px;
        // background-color: aquamarine;
        text-align: center;
        cursor: pointer;
      }
      li:hover {
        color: #13bfff;
      }
      .changeleftnavcolor {
        background-color: #fff;
        color: #13bfff;
      }
    }
    #add {
      width: 30px;
      position: absolute;
      top: 480px;
      left: 39px;
      cursor: pointer;
      img {
        margin-left: 2px;
      }
    }
  }
  .message {
    float: right;
    // background-color: blanchedalmond;
    width: 530px;
    height: 560px;
    .messagehead {
      width: 530px;
      height: 51px;
      // background-color: aquamarine;
      border-bottom: 1px solid #e8ecef;
      .topnav {
        float: left;
        margin-right: 60px;
        height: 51px;
        line-height: 51px;
        cursor: pointer;
        // background-color:  blanchedalmond;
      }
      .topnavblueline {
        border-bottom: 2px solid #13bfff;
      }
      .readall {
        float: right;
        width: 20px;
        height: 20px;
        margin: 15px;
      }
    }
    .messagebody {
      height: 509px;
      width: 530px;
      padding-right: 8px;
      .read {
        height: 100%;
        overflow: auto;
        li {
          display: inline-block;
          width: 510px;
          // background-color: blanchedalmond;
          border-bottom: 1px solid #e8ecef;
          padding: 20px 0 19px 10px;
          .readcolor {
            width: 14px;
            height: 14px;
            background: #ffc108;
            border-radius: 3px;
            float: left;
          }
          .readcolor2 {
            background: grey;
            width: 14px;
            height: 14px;
            border-radius: 3px;
            float: left;
          }
          .title {
            width: 110px+335px+24px;
            height: 20px;
            float: left;
            margin: -4px 0 0 7px;
            .stime {
              width: 110px;
              height: 20px;
              overflow: hidden;
              float: left;
            }
          }
          .length {
            float: right;
          }
          .starandend {
            float: left;
            margin: 20px 0 0 9px;
            span {
              margin-left: 9px;
            }
            .content {
              display: inline-block;
              width: 484px;
            }
          }
        }
      }
    }
    .remindlist {
      width: 530px;
      height: 509px;
      // background-color: grey;
      .messagetable {
        width: 518px;
        height: 489px;
        overflow: auto;
        table {
          width: 505px;
          // background-color: grey;
          margin-top: 20px;
          text-align: center;
          border-top: 1px solid #e8ecef;
          border-left: 1px solid #e8ecef;
          border-spacing: 0;
          // border-color:#E8ECEF;
          // border-collapse: collapse;
          td,
          th {
            border-right: 1px solid #e8ecef;
            border-bottom: 1px solid #e8ecef;
          }
          .tableheadrow {
            height: 50px;
            background: #eff3f6;
            color: #8590b3;
          }
          .tablebodyrow {
            height: 65px;
          }
          .remindcontent {
            width: 288px;
          }
          .creattime {
            width: 110px;
          }
          td.creattime {
            color: #334681;
            font-size: 12px;
          }
          .todo {
            width: 110px;
          }
          img {
            margin-right: 20px;
            cursor: pointer;
          }
          .remindconcretecontent {
            width: 288px;
            height: 65px;
            div {
              width: 288px;
              height: 65px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              line-height: 65px;
            }
          }
          .icon-opera {
            .ico {
              display: inline-block;
              width: 18px;
              height: 18px;
            }
            .pencil {
              margin-right: 20px;
              background-image: url('../../assets/image/progress/remind/pencil.png');
            }
            .pencil:hover {
              background-image: url('../../assets/image/progress/remind/pencil2.png');
            }
            .trash {
              background-image: url('../../assets/image/progress/remind/trash.png');
            }
            .trash:hover {
              background-image: url('../../assets/image/progress/remind/trash2.png');
            }
          }
        }
      }
    }
  }
}
</style>
