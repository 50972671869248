<template>
  <div id="app" @click="overall">
    <sidebar ref="sidebar" />
    <div id="main" ref="main" v-if="$store.state.isLogin">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
      <remindmessage
        :show-all="$store.state.remind"
        v-if="$store.state.remind"
        :is-get-data="$store.state.remindPopup"
        @changeShow="remindShow()"
      />
      <remindpopup @ctrlpopup="ctrlpopup" v-show="$store.state.remindPopup" />
    </div>
  </div>
</template>

<script>
import remindmessage from '@/components/remind/message';
import remindpopup from '@/components/remind/remindpopup';
import sidebar from '@/components/sidebar/sidebar';
import Bus from '@/assets/js/Bus';
import { postTaskUser } from '@/service/help';
export default {
  data() {
    return {};
  },
  components: {
    sidebar,
    remindmessage,
    remindpopup,
  },
  methods: {
    ctrlpopup(data) {
      this.$store.commit('set', { type: 'remindPopup', data });
    },
    remindShow() {
      this.$store.commit('set', { type: 'remind', data: false });
    },
    overall() {
      // 全局点击方法
      this.$refs.sidebar.showSetMenu = false;
    },
  },
  created() {
    this.$store.commit('set', { type: 'Bus', data: Bus });
    postTaskUser({ id: '' }).then(res => {
      this.$store.commit('set', { type: 'member', data: res.data.data });
    });
  },
};
</script>

<style lang="less">
@import '~@/assets/style/base.less';
@import '~@/assets/data-picker-style.less';
.search-input {
  padding-right: 32px;
  background: url('~@/assets/image/icon-progress/icon_search@2x.png') no-repeat 16px 12px;
  background-size: 16px 16px;
  padding-left: 43px;
  flex-grow: 1;
  height: 40px;
  width: 100%;
  border: 1px solid #e8ecef;
  border-radius: 10px;
}
.search-input::placeholder {
  color: #8590b3;
}
</style>
