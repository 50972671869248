<template>
  <div>
    <div class="bg" @click="closeNewRemind"></div>
    <div class="main" @click="resetShow">
      <div class="head">
        <span>新建提醒</span>
        <img
          :src="close1"
          alt=""
          v-show="changeClosecolor === false"
          @mouseenter="changeClosecolor = !changeClosecolor"
          @click="closeNewRemind"
          style="cursor:pointer;"
        />
        <img
          :src="close2"
          alt=""
          v-show="changeClosecolor === true"
          @mouseleave="changeClosecolor = !changeClosecolor"
          @click="closeNewRemind"
          style="cursor:pointer;"
        />
      </div>

      <div class="line"></div>
      <div class="remindcontent">
        <div>提醒内容</div>
        <textarea type="text" v-model="remindValue" placeholder="请输入提醒内容"></textarea>
      </div>
      <div class="data">
        <div class="starstr">提醒时间</div>
        <div
          class="stardata"
          @click="(changeDataColor1 = !changeDataColor1), $refs.remindas.focus()"
          :class="{ changeblue: changeDataColor1 === true }"
        >
          <div class="block">
            <el-date-picker
              style="opacity: 0"
              prefix-icon="prefix-icon"
              id="remind-stime"
              ref="remindas"
              v-model="stime"
              type="datetime"
              placeholder="选择提醒时间"
              :picker-options="start_Date"
            >
            </el-date-picker>
          </div>
          <span>{{ parseData(stime) }}</span>
          <div class="img" />
        </div>
      </div>

      <div class="btn">
        <div class="btn1" @click="okAddRemind">确定</div>
        <div class="btn2" @click="closeNewRemind">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { postRemindNew } from '@/service/help';

export default {
  name: 'newremind',
  data() {
    return {
      openNewRemind: false,
      close1: require('@/assets/image/progress/addTask/close1.png'),
      close2: require('@/assets/image/progress/addTask/close2.png'),
      changeClosecolor: false,
      data1: require('@/assets/image/progress/remind/data1.png'),
      data2: require('@/assets/image/progress/remind/data2.png'),
      changeDataColor1: false,
      remindValue: '',
      stime: '',
      start_Date: {
        disabledDate: time => {
          return time.getTime() < new Date() - 8.64e7;
        },
      },
    };
  },
  props: ['a'],
  methods: {
    closeNewRemind() {
      this.$emit('child-event', this.openNewRemind);
      this.remindValue = '';
    },
    resetShow() {
      this.changeDataColor1 = false;
    },
    parseData(time) {
      if (time === '') return '';
      else {
        return time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate();
      }
    },
    parseData1(data) {
      if (typeof data === 'object') {
        let y = data.getFullYear();
        let m = data.getMonth() + 1;
        let d = data.getDate();
        let h = data.getHours();
        let mi = data.getMinutes();
        let s = data.getSeconds();
        h = h < 10 ? '0' + h : h;
        mi = mi < 10 ? '0' + mi : mi;
        s = s < 10 ? '0' + s : s;
        return y + '-' + m + '-' + d + ' ' + h + ':' + mi + ':' + s;
      } else return '';
    },
    okAddRemind() {
      postRemindNew({
        content: this.remindValue,
        stime: this.parseData1(this.stime),
      }).then(res => {
        if (res.data.success) {
          console.log(res.data);
          this.closeNewRemind();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  width: 4px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 3px;
  // background-color: transparent;
  background-color: #eff3f6;
  height: 489px;
  margin: 10px 0 10px 0;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: none;
  background-color: #c2c7d9;
}

textarea::-webkit-input-placeholder {
  color: #c2c7d9;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c2c7d9;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c2c7d9;
}
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c2c7d9;
}

.bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}
.main {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -330px;
  margin-top: -165px;
  width: 660px;
  height: 331px;
  background-color: #ffffff;
  z-index: 15;
  border-radius: 4px;
  .head {
    font-size: 18px;
    width: 72px+527px+13px;
    height: 25px;
    margin: 13px 0 0 30px;
    img {
      float: right;
      margin-top: 4px;
    }
  }
  .line {
    width: 660px;
    height: 1px;
    background: #e8ecef;
    opacity: 1;
    margin-top: 12px;
  }
  .remindcontent {
    height: 72px;
    width: 100%;
    margin-top: 20px;
    // background-color: hotpink;
    div {
      font-size: 16px;
      margin: 8px 16px 0 70px;
      float: left;
    }
    textarea {
      float: left;
      width: 476px;
      height: 72px;
      border: 1px solid #e8ecef;
      border-radius: 4px;
      padding: 5px 10px 5px 10px;
      resize: none;
      font-size: 14px;
    }
  }
  .data {
    width: 100%;
    height: 36px;
    margin-top: 20px;
    div {
      float: left;
    }
    .starstr {
      font-size: 16px;
      margin: 7px 16px 0 70px;
      width: 64px;
      height: 22px;
    }
    .stardata {
      width: 180px;
      height: 36px;
      border: 1px solid #e8ecef;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      line-height: 36px;
      .img {
        float: right;
        margin: 10px;
        display: inline-block;
        width: 17px;
        height: 16px;
        background-image: url('../../assets/image/progress/remind/data1.png');
      }
      .img:hover {
        background-image: url('../../assets/image/progress/remind/data2.png');
      }
    }
    .changeblue {
      border: 1px solid #13bfff;
      .img {
        background-image: url('../../assets/image/progress/remind/data2.png');
      }
    }

    /deep/.block {
      width: 1px;
      position: absolute;
      #remind-stime {
        width: 1px;
        padding: unset;
        pointer-events: none;
      }
      .el-date-editor.el-input {
        width: 1px;
      }
    }
  }
  .btn {
    width: 100%;
    height: 36px;
    margin-top: 34px;
    font-weight: 400;
    .btn1 {
      width: 94px;
      height: 36px;
      background-color: #13bfff;
      color: #fff;
      border-radius: 4px;
      float: left;
      margin-left: 150px;
      padding: 7px 0 0 30px;
      cursor: pointer;
    }
    .btn2 {
      color: #b590b3;
      width: 94px;
      height: 36px;
      border: 1px solid #e8ecef;
      border-radius: 4px;
      float: left;
      margin-left: 16px;
      padding: 6px 0 0 29px;
      cursor: pointer;
    }
  }
}
</style>
